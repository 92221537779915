// import React, { useRef, useEffect } from "react";
import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import Layout from "layouts/Main";
import {
  SEO,
  Button,
  ReadMore,
  HighlightedText,
  Title,
  Subtitle,
  Text,
  FallingText,
} from "components";
import { Fade } from "react-awesome-reveal";
import Dome from "images/svg/duomo.svg";
import DomeLogo from "images/svg/duomo-logo.svg";
import Quotation from "images/svg/quotation.svg";
import loadable from "@loadable/component";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ExpoScaleEase } from "gsap/EasePack";
import FigmaSVG from "images/svg/figma.svg";
import Blabla from "images/svg/blabla.svg";
import GatsbySVG from "images/svg/gatsby.svg";
import VercelSVG from "images/svg/vercel.svg";
import AwsSVG from "images/svg/amazonaws.svg";
import NextSVG from "images/svg/next-js.svg";
import TreedomSVG from "images/svg/treedom.svg";
import TdvbSVG from "images/svg/tdvb.svg";
import GithubSVG from "images/svg/github.svg";
import AdobeSVG from "images/svg/adobe.svg";
import DjangoSVG from "images/svg/django.svg";
import ContentfulSVG from "images/svg/contentful.svg";
import NotionSVG from "images/svg/notion.svg";
import ScalewaySVG from "images/svg/scaleway.svg";
import SaleorSVG from "images/svg/saleor.svg";

const AnimatedPortfolio = loadable(() =>
  import("../components/AnimatedPortfolio")
);

const Box = ({ children, className }) => (
  <div
    className={`p-8 2xl:p-10 border-black border-4 bg-white shadow-button rounded-md ${className} mr-6`}
  >
    {children}
  </div>
);

function IndexPage({ data }) {
  const [isInitialRenderCompleted, setIsInitialRenderCompleted] = useState(
    false
  );
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(ExpoScaleEase);

  const {
    allContentfulCustomer: { nodes: customers },
    allContentfulBlogPost: { nodes: blogPost },
  } = data;

  const sliderRef = useRef();
  const toHighlightRef = useRef();
  const moveVideoRef = useRef();
  const testRef = useRef();
  const tdvbRef = useRef();
  const domeRef = useRef();

  useLayoutEffect(() => {
    if (!tdvbRef || !tdvbRef.current) return null;

    const tdvbResponsive = gsap.matchMedia();

    tdvbResponsive
      .add("(max-width: 680px)", () => {
        gsap
          .timeline({
            scrollTrigger: {
              trigger: tdvbRef?.current,
              pin: true,
              // markers: true,
              start: "50% top",
              pinSpacing: true,
              end: "bottom bottom",
              scrub: 3,
            },
          })
          .fromTo(
            tdvbRef.current.querySelector("#bg-green"),
            {
              scale: 1,
              autoAlpha: 1,
              width: "50vw",
              height: "50vh",
            },
            {
              // scale: 10,
              ease: "easeinout",
              autoAlpha: 1,
              width: "92vw",
              height: "95vh",
            },
            0
          );
      })
      .add("(min-width: 700px)", () => {
        gsap
          .timeline({
            scrollTrigger: {
              trigger: tdvbRef?.current,
              pin: true,
              // markers: true,
              start: "50% top",
              pinSpacing: true,
              end: "bottom bottom",
              scrub: 3,
            },
          })
          .fromTo(
            tdvbRef.current.querySelector("#bg-green"),
            {
              scale: 1,
              autoAlpha: 1,
              width: "50vw",
              height: "50vh",
            },
            {
              // scale: 10,
              ease: "easeinout",
              autoAlpha: 1,
              width: "92vw",
              height: "95vh",
            },
            0
          );
      });
    // onReverseComplete: () => videos?.forEach((video) => video.pause()),
    const textTl = gsap.timeline({
      scrollTrigger: {
        trigger: tdvbRef?.current,
        // pin: true,
        // markers: true,
        start: "50% top",
        end: "bottom 120%",
        scrub: 3,
      },
    });

    textTl.fromTo(
      tdvbRef.current.querySelector("#treedom"),
      {
        autoAlpha: 1,
      },
      {
        autoAlpha: 0,
      }
    );
    // onReverseComplete: () => videos?.forEach((video) => video.pause()),
    textTl.fromTo(
      tdvbRef.current.querySelector("#text"),
      {
        autoAlpha: 0,
        yPercent: 50,
      },
      {
        autoAlpha: 1,
        yPercent: 0,
      },
      0
    );
    // tl.to(ps, { display: "none" }, ">");
  }, []);
  useLayoutEffect(() => {
    if (!domeRef || !domeRef.current) return null;

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: domeRef?.current,
        pin: true,
        start: "12% top",
        pinSpacing: true,
        end: "bottom 50%",
        scrub: 3,
      },
    });

    tl.from(domeRef.current.querySelector("#treedom"), {
      width: "70vw",
      height: "70vh",
    });

    tl.from(
      domeRef.current.querySelector("#redText"),
      {
        autoAlpha: 0,
        yPercent: 200,
      },
      "<"
    );

    const brightnessTl = gsap.timeline({
      scrollTrigger: {
        trigger: domeRef?.current,
        // markers: true,
        start: "top 50%",
        end: "bottom bottom",
        scrub: 3,
      },
    });

    brightnessTl.fromTo(
      domeRef.current.querySelector("#treedom"),
      {
        webkitFilter: "brightness(1)",
        filter: "brightness(1)",
      },
      {
        webkitFilter: "brightness(0.5)",
        filter: "brightness(0.5)",
      }
    );
  }, []);

  useEffect(() => {
    setIsInitialRenderCompleted(true);
  }, []);

  return (
    <>
      <div className="absolute w-full h-full ">
        {/* <NotForCrawlers> */}
        {/* {isInitialRenderCompleted && <Particles hideText={true} />} */}
        {/* </NotForCrawlers> */}
      </div>
      <Layout>
        <SEO
          keywords={["Trame Digitali"]}
          title="Trame Digitali | React Digital Web Agency @ Prato, Firenze, Pistoia"
          titleTemplate="%s"
          description="Forniamo alla tua azienda la strategia per crescere sul web grazie ad una gamma completa di soluzioni di marketing, sviluppo web, e-commerce e SEO"
        />

        <div
          className="w-full flex flex-col md:px-0 relative z-40"
          ref={moveVideoRef}
        >
          <section
            className="h-screen relative  flex items-center justify-center text-left px-12 md:px-0"
            id="testTrigger"
          >
            <div className="mx-auto container sm:max-w-2xl md:max-w-4xl xl:max-w-5xl  flex flex-col h-screen align-items justify-center pt-44 pb-28 ">
              <div className=" flex flex-col text-center" ref={testRef}>
                <div className="">
                  <Title
                    center
                    weight="black"
                    size={"hero"}
                    margin={false}
                    disableAnimation={true}
                  >
                    Sviluppiamo esperienze
                  </Title>
                </div>
                <div className="">
                  <Title
                    center
                    weight="black"
                    size={"hero"}
                    margin={false}
                    disableAnimation={true}
                  >
                    digitali che i tuoi clienti
                    <HighlightedText
                      className={"w-min inline"}
                      ref={toHighlightRef}
                      size="hero"
                    >
                      {" ameranno"}
                    </HighlightedText>
                  </Title>
                </div>
              </div>
              <div className="pt-8 flex flex-col md:flex-row justify-center items-center">
                {/* <div className="p-2 md:px-4">
                  <Button typeformId="qT0c2QIo" bigger>
                    Call to action
                  </Button>
                </div>
               <div className="hidden md:block p-2 md:px-4">
                  <ReadMore white>
                    Dai un&apos;occhiata ai nostri lavori!
                  </ReadMore>
                </div> */}
              </div>
              <div className="flex flex-col justify-center items-center pt-16">
                <Text size="small" opaque>
                  Abbiamo lavorato con
                </Text>
                <div
                  ref={sliderRef}
                  className="pt-2 grid grid-cols-4 grid-rows-2 md:grid-cols-8 md:grid-rows-1 gap-x-3 gap-y-4 md:gap-4 md:px-16 "
                >
                  {customers.map((customer, index) => {
                    const image = getImage(customer?.logo);
                    if (!image) return;
                    return (
                      <GatsbyImage
                        key={index}
                        objectFit="contain"
                        image={image}
                        className="h-12 w-auto object-contain"
                        alt={`${customer?.name} logo`}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </section>

          <section
            className="h-animation px-12 md:px-0 pt-56 pb-72 md:py-56 2xl:pt-64 2xl:pb-56"
            id="start"
          >
            <div className="relative w-full flex flex-col justify-center items-center  md:px-36">
              <div
                className="flex flex-col justify-center items-center text-left md:text-center md:w-1/2 2xl:w-1/3  md:mx-auto sal-animate"
                id="pinwhilescroll"
              >
                <div className="flex flex-col">
                  <FallingText color="black" size="base">
                    Strategia
                  </FallingText>
                  <FallingText color="black" size="base">
                    Tecnologia
                  </FallingText>
                  <FallingText color="black" size="base">
                    Contenuti
                  </FallingText>
                  <FallingText color="black" size="base">
                    Brand
                  </FallingText>
                  <Text>
                    Forniamo alla tua azienda una strategia su misura per
                    crescere sul web grazie ad una gamma completa di soluzioni
                    di sviluppo web, e-commerce, marketing, e brand identity.
                    <br />
                    Un unico interlocutore per i tuoi progetti sul web.
                  </Text>
                  <div className="w-full flex flex-col md:flex-row md:justify-center md:items-center md:text-center pr-20 md:pr-0">
                    <div className="pt-2 flex flex-col md:flex-row justify-center items-start md:items-center">
                      <div className="p-2 md:mx-4">
                        <Button typeformId="qT0c2QIo" bigger>
                          Iniziamo oggi!
                        </Button>
                      </div>
                      <div className="hidden md:flex items-center justify-center"></div>
                    </div>
                  </div>
                </div>
              </div>
              <AnimatedPortfolio ref={moveVideoRef} />
            </div>
          </section>
          <section className="px-12 mb-40 md:mb-0 md:px-0">
            <div
              className="flex flex-row justify-center items-center relative"
              style={{ height: "130vh" }}
              ref={domeRef}
            >
              <div
                style={{ width: "92vw", height: "95vh" }}
                className="absolute flex justify-center items-center"
                id="bg-red"
              >
                <div
                  className="absolute overflow-hidden w-full h-full"
                  id="treedom"
                >
                  <StaticImage
                    placeholder="tracedSVG"
                    alt="florence dome "
                    formats={["webp", "avif", "png"]}
                    src={"../images/dome.jpg"}
                    objectFit="cover"
                    className="h-screen w-screen"
                  />
                </div>
                <div
                  className="absolute h-full flex flex-col items-center justify-center text-center bg-transparent px-12"
                  id="redText"
                >
                  <div className="flex flex-col py-8 md:pl-4  md:px-12 2xl:px-32 md:w-1/2  relative">
                    <Dome className="hidden md:block absolute bottom-16 -right-16 w-24 h-auto transform rotate-12  fill-current text-primary opacity-50"></Dome>
                    <div className="md:w-screen flex items-center md:items-center justify-center md:justify-start relative text-left">
                      <div className="md:w-1/2">
                        <Quotation className="absolute -top-20 -left-4  opacity-30 fill-current text-white h-28 w-auto" />
                        <Title color="white" size="extraSmall">
                          Da anni Opera collabora con soddisfazione con Rudi e
                          Cosimo, oggi Trame Digitali. Un rapporto di fiducia
                          tecnico e umano che ci ha garantito sempre risposte
                          rapide e precise per applicazioni critiche come
                          biglietteria online e prenotazioni visite, che servono
                          migliaia di visitatori ogni giorno.
                        </Title>
                      </div>
                    </div>
                    <div className="pt-4 flex items-start justify-start">
                      <DomeLogo
                        className="pt-1 h-10 w-auto px-4 hidden md:block"
                        style={{ filter: "brightness(13)" }}
                      />
                      <div className="flex flex-col items-start md:items-start">
                        <div className="flex items-center justify-between">
                          <DomeLogo
                            className="h-16 w-auto block md:hidden"
                            style={{ filter: "brightness(13)" }}
                          />
                          <div className="hidden md:block">
                            <Subtitle white big bolder>
                              OPERA DI SANTA MARIA DEL FIORE
                            </Subtitle>
                          </div>
                        </div>
                        <div className="flex w-full md:w-auto justify-start pt-2 md:pt-0">
                          <Subtitle white bold>
                            Lorenzo Luchetti,
                          </Subtitle>
                          <Subtitle white opaque>
                            &nbsp; direttore generale
                          </Subtitle>
                        </div>
                      </div>
                    </div>
                    <div className="pt-4  flex flex-col md:flex-row justify-start items-start">
                      <div className="p-2 md:px-4">
                        <Link to="/clienti/il-grande-museo-del-duomo/">
                          <Button bigger>Occhiatina al case study?</Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <section className="hidden px-12 md:px-0">
            <div
              className="flex flex-row justify-center items-center relative"
              style={{ height: "120vh" }}
            >
              <div className=" absolute flex justify-center items-center bg-primary">
                <div
                  className="flex mt-32 mb-32 md:mt-24 md:mb-32 2xl:mt-72 px-12 md:px-0"
                  id="redText"
                >
                  <div className="flex flex-col justify-center items-center relative w-full">
                    <div className="flex flex-col py-8 md:pl-4  md:mx-12 2xl:mx-32 md:w-1/2  relative">
                      <Dome className="hidden md:block absolute bottom-16 -right-16 w-24 h-auto transform rotate-12  fill-current text-primary opacity-50"></Dome>
                      <div className=" flex items-center justify-center relative text-right">
                        <Quotation className="absolute -top-20 -left-12  opacity-70 fill-current text-primary h-28 w-auto" />
                        <Text color="black" size="base">
                          Da anni Opera collabora con soddisfazione con Rudi e
                          Cosimo, oggi Trame Digitali. Un rapporto di fiducia
                          tecnico e umano che ci ha garantito sempre risposte
                          rapide e precise per applicazioni critiche come
                          biglietteria online e prenotazioni visite, che servono
                          migliaia di visitatori ogni giorno.
                        </Text>
                      </div>
                      <div className="mt-4 flex items-center justify-center">
                        <DomeLogo className="mt-1 h-10 w-auto mx-4 hidden md:block" />
                        <div className="flex flex-col items-center md:items-start">
                          <div className="flex items-center justify-between">
                            <DomeLogo className="h-16 w-auto block md:hidden" />
                            <div className="hidden md:block">
                              <Subtitle big bolder>
                                OPERA DI SANTA MARIA DEL FIORE
                              </Subtitle>
                            </div>
                          </div>
                          <div className="flex w-full md:w-auto justify-start mt-2 md:mt-0">
                            <Subtitle bold>Lorenzo Luchetti,</Subtitle>
                            <Subtitle opaque>
                              &nbsp; direttore generale
                            </Subtitle>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          <section className="relative md:my-16 px-12 md:px-0">
            <div className="absolute w-full h-screen z-30">
              <div className="hidden md:block w-full md:w-2/5 bottom-64 md:left-16 md:bottom-2/3 xl:bottom-64 xl:left-32 absolute z-20">
                <StaticImage
                  placeholder="tracedSVG"
                  alt="extract data from maze illustration"
                  formats={["webp", "avif", "png"]}
                  src={"../images/eye.png"}
                  className=""
                />
              </div>
              <div className="block md:hidden -top-36 absolute w-full md:w-2/5 z-20">
                <StaticImage
                  placeholder="tracedSVG"
                  alt="extract data from maze illustration"
                  formats={["webp", "avif", "png"]}
                  src={"../images/eye.png"}
                  className=""
                />
              </div>
            </div>
            <div className="relative  w-full flex flex-col justify-end items-end md:px-32 py-40 md:py-32 z-40">
              <div className="flex flex-col text-right md:text-left md:w-1/2 align-middle">
                <Title headingLevel="h2" right weight="black">
                  Diamo valore alle performance
                </Title>
                <Text right>
                  Tutte le nostre decisioni ed i nostri progetti sono{" "}
                  <strong>data-driven</strong>, ovvero basati sui tuoi dati e
                  misurabili nei loro effetti. Non siamo solo programmatori, non
                  siamo solo creativi o marketers, perchè non è ciò che ti
                  serve: quello che fa la differenza è la{" "}
                  <strong>strategia</strong>.
                </Text>
              </div>
              <div className="flex justify-center items-center mt-6">
                {/* <div className="hidden md:block mx-8">
                  <ReadMore transparent>
                    Perchè ho bisogno di una strategia?
                  </ReadMore>
                </div> */}
                <div className="">
                  <Button bigger inverted typeformId="qT0c2QIo">
                    Ok, parliamone!
                  </Button>
                </div>
                <div className="hidden md:block mx-8">
                  <Link to="/come-lavoriamo/">
                    <ReadMore white>Come lavoriamo?</ReadMore>
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className="mt-8 relative px-12 md:px-0">
            <div className="absolute w-full h-screen">
              <div className="w-1/3 top-0 xl:-top-32  md:right-0  hidden md:block md:absolute z-20">
                <StaticImage
                  placeholder="tracedSVG"
                  alt="unbreakable bond partner customer scissor illustration"
                  formats={["webp", "avif", "png"]}
                  src={"../images/scissor.png"}
                  imgClassName=""
                />
              </div>

              <div className="w-2/3 -top-32 -right-4  absolute block md:hidden z-20">
                <StaticImage
                  placeholder="tracedSVG"
                  alt="unbreakable bond partner customer scissor illustration"
                  formats={["webp", "avif", "png"]}
                  src={"../images/scissor.png"}
                  imgClassName=""
                />
              </div>
            </div>

            <div className="relative mt-12 md:mt-0 w-full flex flex-col justify-end md:px-32 py-40 md:py-32 ">
              <div className="flex flex-col text-left md:text-left md:w-1/2 align-middle">
                <Title headingLevel="h2" weight="black">
                  Costruiamo relazioni
                </Title>
                <Text>
                  Per un progetto di successo la <strong>fiducia</strong> viene
                  prima ancora della strategia e della competenza tecnica. Ci
                  stai affidando parti critiche del tuo business e lo tratteremo
                  come se fosse il nostro.
                  <br />
                  E' l'unica ricetta per costruire rapporti duraturi nel tempo e
                  solo un rapporto duraturo può portare a{" "}
                  <strong>risultati concreti</strong>. In Trame Digitali prima
                  che social media manager, marketers, programmatori esperti
                  troverai <strong>persone</strong> pronte ad{" "}
                  <strong>ascoltarti</strong>.
                  <br />
                </Text>
              </div>
              <div className="flex justify-start text-left mt-6 md:pl-0 md:pr-72 z-40">
                <div className="">
                  <Button bigger inverted typeformId="qT0c2QIo">
                    Presentaci il tuo progetto
                  </Button>
                </div>
                <div className="hidden md:block mx-8">
                  <ReadMore transparent>
                    Vuoi conoscere chi lavorerà con te?
                  </ReadMore>
                </div>
              </div>
            </div>
          </section>

          {/* <section className="relative  mt-44 mb-16">
            <div className="flex flex-col md:mx-12">
              <BlogPreview blogPosts={blogPost} />
            </div>
          </section> */}
          {/* <section className="mb-12">
            <div className="relative  w-full flex flex-col justify-center md:px-16">
              <div className="flex flex-col items-center justify-center text-center"> */}
          {/* <Title size={"small"}>
                  Se cerchi soluzioni far crescere la tua azienda sei nel posto
                  giusto.
                </Title> */}
          {/* <Title>Trame Digitali 2021 in numeri</Title>
              </div> */}
          {/* <div className="flex flex-col md:flex-row">
                <Box bgColor={""} shadowColor={""} textColor={""}>
                  <Title headingLevel="h5">1008</Title>
                  <Subtitle>Post creati sui social</Subtitle>
                </Box>
                <Box bgColor={""} shadowColor={""} textColor={""}>
                  <Title headingLevel="h5">170k</Title>
                  <Subtitle>
                    Euro di media budget <br />
                    gestiti per i clienti
                  </Subtitle>
                </Box>
                <Box bgColor={""} shadowColor={""} textColor={""}>
                  <Title headingLevel="h5">2</Title>
                  <Subtitle>Nuovi membri del Team</Subtitle>
                </Box>
                <Box bgColor={""} shadowColor={""} textColor={""}>
                  <Title headingLevel="h5">126</Title>
                  <Subtitle>Gin tonic bevuti</Subtitle>
                </Box>
                <Box bgColor={""} shadowColor={""} textColor={""}>
                  <Title headingLevel="h5">4.590</Title>
                  <Subtitle>Kg di C02 risparmiata</Subtitle>
                </Box>
              </div> */}
          {/* </div>
          </section> */}
          <div className="wrapper md:hidden font-serif ">
            <div className="marquee tracking-wider">
              <p className="text-6xl md:text-biggest  text-white flex">
                <FigmaSVG className="w-16 h-16 mx-4" />
                <Blabla className="w-16 h-16 mx-4" />
                <GatsbySVG className="w-16 h-16 mx-4" />
                <VercelSVG className="w-16 h-16 mx-4" />
                <AdobeSVG className="w-16 h-16 mx-4" />
                <DjangoSVG className="w-16 h-16 mx-4" />
                <ContentfulSVG className="w-16 h-16 mx-4" />
                <AwsSVG className="w-16 h-16 mx-4" />
                <NextSVG className="w-16 h-16 mx-4" />
                <GithubSVG className="w-16 h-16 mx-4" />
                <NotionSVG className="w-16 h-16 mx-4" />
                <ScalewaySVG className="w-16 h-16 mx-4" />
                <SaleorSVG className="w-16 h-16 mx-4" />
              </p>
              <p className="text-6xl md:text-biggest text-white">
                <AwsSVG className="w-16 h-16 mx-4" />
                <NextSVG className="w-16 h-16 mx-4" />
                <GithubSVG className="w-16 h-16 mx-4" />
                <NotionSVG className="w-16 h-16 mx-4" />
                <ScalewaySVG className="w-16 h-16 mx-4" />
                <SaleorSVG className="w-16 h-16 mx-4" />
                <FigmaSVG className="w-16 h-16 mx-4" />
                <Blabla className="w-16 h-16 mx-4" />
                <GatsbySVG className="w-16 h-16 mx-4" />
                <VercelSVG className="w-16 h-16 mx-4" />
                <AdobeSVG className="w-16 h-16 mx-4" />
                <DjangoSVG className="w-16 h-16 mx-4" />
                <ContentfulSVG className="w-16 h-16 mx-4" />
              </p>
            </div>
          </div>
          <section className="w-full md:my-24 px-12 md:px-0">
            <div className="relative w-full flex flex-col items-start justify-start md:mx-32">
              <div className="w-full flex flex-1 flex-col xl:flex-row xl:items-center justify-center xl:justify-end text-left rounded-xl px-0 xl:py-20 xl:px-16">
                <div className="flex flex-col mt-12">
                  <div className="flex flex-row justify-start">
                    <FallingText color="black" size="base">
                      Portiamo impatto
                    </FallingText>
                  </div>

                  <Title
                    disableAnimation
                    margin={false}
                    color={"black"}
                    size="base"
                  >
                    e valore con
                  </Title>
                  <Title disableAnimation color={"black"} size="base">
                    tecnologie innovative
                  </Title>
                  <div className="flex md:hidden">
                    <Text color={"black"}>
                      Tutte le nostre decisioni ed i nostri progetti sono{" "}
                      <strong>data-driven</strong>, ovvero basati sui tuoi dati
                      e misurabili nei loro effetti.
                    </Text>
                  </div>
                </div>

                {/* <div className="">
                  
                </div> */}

                {/* <div className="text-primary mb-16">
                  <Text color="red" size="small">
                    Questo l&apos;abbiamo messo in inglese perchè suonava troppo
                    meglio 😉
                  </Text>
                </div> */}
                <div className="hidden max-w-full md:flex flex-col mt-6 xl:ml-32 xl:px-6">
                  <div className="w-full mb-12">
                    <Text color={"black"}>
                      Non solo marketing e social media: Trame Digitali ha un
                      vero e proprio team di sviluppatori. Porteremo la tua
                      azienda nel mondo del JAM Stack e la doteremo dei migliori
                      sistemi.
                    </Text>
                  </div>

                  <div className="w-full hidden md:flex flex-1  flex-col md:flex-row justify-center items-center ">
                    <div className="flex flex-col flex-1 w-full justify-start">
                      <div className="flex flex-col items-start justify-center mr-24">
                        <div className="mb-8 mt-2">
                          <Text color={"black"} weight="black">
                            Frameworks
                          </Text>
                        </div>
                        <div className="flex flex-row">
                          <Fade direction="left" cascade="true" className="">
                            <div className="group flex flex-col items-start justify-center">
                              <GatsbySVG className=" w-12 h-12" />
                              <div className="opacity-0 group-hover:opacity-70 transition-all duration-700 mt-2">
                                <Text color={"white"} size="smaller">
                                  Gatsby
                                </Text>
                              </div>
                            </div>
                            <div className="group flex flex-col items-center justify-center">
                              <Blabla className="w-12 h-12 mx-1  " />
                              <div className="opacity-0 group-hover:opacity-70 transition-all duration-700 mt-2">
                                <Text color={"white"} size="smaller">
                                  React
                                </Text>
                              </div>
                            </div>
                            <div className="group flex flex-col items-center justify-center">
                              <DjangoSVG className="  w-12 h-12 mx-1" />
                              <div className="opacity-0 group-hover:opacity-70 transition-all duration-700 mt-2">
                                <Text color={"white"} size="smaller">
                                  Django
                                </Text>
                              </div>
                            </div>
                            {/* <div className="group flex flex-col items-center justify-center">
                            <DenoSVG className=" w-12 h-12 mx-1" />
                            <div className="opacity-0 group-hover:opacity-70 transition-all duration-700 mt-2">
                              <Text color={"white"}>Deno</Text>
                            </div>
                          </div> */}
                            <div className="group flex flex-col items-center justify-center">
                              <NextSVG className=" w-12 h-12 mx-1" />
                              <div className="opacity-0 group-hover:opacity-70 transition-all duration-700 mt-2">
                                <Text color={"white"} size="smaller">
                                  Next.js
                                </Text>
                              </div>
                            </div>
                          </Fade>
                        </div>
                      </div>
                      <div className="flex flex-col items-start justify-center">
                        <div className="mb-8 mt-2">
                          <Text color={"black"} weight="black">
                            Design & Collab
                          </Text>
                        </div>
                        <div className="flex">
                          <Fade direction="bottom" cascade>
                            <div className="group flex flex-col items-start justify-center">
                              <FigmaSVG className=" w-12 h-12 mr-2" />
                              <div className="opacity-0 group-hover:opacity-70 transition-all duration-700 mt-2">
                                <Text color={"white"} size="smaller">
                                  Figma
                                </Text>
                              </div>
                            </div>
                            <div className="group flex flex-col items-center justify-center">
                              <NotionSVG className=" w-12 h-12 mx-1" />
                              <div className="opacity-0 group-hover:opacity-70 transition-all duration-700 mt-2">
                                <Text color={"white"} size="smaller">
                                  Notion
                                </Text>
                              </div>
                            </div>
                            <div className="group flex flex-col items-center justify-center">
                              <AdobeSVG className=" w-12 h-12 mx-4" />
                              <div className="opacity-0 group-hover:opacity-70 transition-all duration-700 mt-2">
                                <Text color={"white"} size="smaller">
                                  Notion
                                </Text>
                              </div>
                            </div>
                          </Fade>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col flex-1 w-full justify-start">
                      <div className="flex flex-col items-start justify-start mr-24">
                        <div className="mb-8 mt-2">
                          <Text color={"black"} weight="black">
                            Headless
                          </Text>
                        </div>
                        <div className="flex">
                          <Fade direction="bottom" cascade>
                            <div className="group flex flex-col items-start justify-center">
                              <ContentfulSVG className=" w-12 h-12 mr-2" />
                              <div className="opacity-0 group-hover:opacity-70 transition-all duration-700 mt-2">
                                <Text color={"white"} size="smaller">
                                  Contentful
                                </Text>
                              </div>
                            </div>
                            <div className="group flex flex-col items-center justify-center">
                              <SaleorSVG className=" w-12 h-12 mx-1 filter grayscale" />
                              <div className="opacity-0 group-hover:opacity-70 transition-all duration-700 mt-2">
                                <Text color={"white"} size="smaller">
                                  Saleor
                                </Text>
                              </div>
                            </div>
                          </Fade>
                        </div>
                      </div>
                      <div className="flex flex-col items-start justify-center">
                        <div className="mb-8 mt-2">
                          <Text color={"black"} weight="black">
                            Piattaforme
                          </Text>
                        </div>
                        <div className="flex">
                          <Fade direction="right" cascade>
                            <div className="group flex flex-col items-start justify-center">
                              <VercelSVG className=" w-12 h-12 mr-2" />
                              <div className="opacity-0 group-hover:opacity-70 transition-all duration-700 mt-2">
                                <Text color={"white"} size="smaller">
                                  Vercel
                                </Text>
                              </div>
                            </div>
                            <div className="group flex flex-col items-center justify-center">
                              <AwsSVG className=" w-12 h-12 mx-1" />
                              <div className="opacity-0 group-hover:opacity-70 transition-all duration-700 mt-2">
                                <Text color={"white"} size="smaller">
                                  AWS
                                </Text>
                              </div>
                            </div>
                            <div className="group flex flex-col items-center justify-center">
                              <GithubSVG className=" w-12 h-12 mx-4" />
                              <div className="opacity-0 group-hover:opacity-70 transition-all duration-700 mt-2">
                                <Text color={"white"} size="smaller">
                                  Github
                                </Text>
                              </div>
                            </div>
                            <div className="group flex flex-col items-center justify-center">
                              <ScalewaySVG className=" w-12 h-12 mx-1" />
                              <div className="opacity-0 group-hover:opacity-70 transition-all duration-700 mt-2">
                                <Text color={"white"} size="smaller">
                                  Scaleway
                                </Text>
                              </div>
                            </div>
                          </Fade>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="hidden px-12 md:px-0">
            <div
              className="flex flex-row justify-center items-center relative"
              style={{ height: "120vh" }}
              // ref={tdvbRef}
            >
              <div
                className=" w-96 absolute flex justify-center items-center"
                style={{
                  background:
                    "radial-gradient(circle, rgba(22,219,101,1) 0%, rgba(24,255,109,1) 100%)",
                }}
                // id="bg-green"
              >
                <div className="absolute" id="treedom">
                  <TreedomSVG className="h-12 md:h-48 w-auto filter invert"></TreedomSVG>
                </div>
                <div
                  className="flex flex-col items-center justify-center text-center"
                  id="text"
                >
                  <Title
                    disableAnimation
                    color={"white"}
                    size="base"
                    margin={false}
                  >
                    Nel 2021
                  </Title>

                  <Title
                    disableAnimation
                    color={"white"}
                    size="base"
                    margin={false}
                  >
                    insieme a Treedom
                  </Title>

                  <Title
                    disableAnimation
                    color={"white"}
                    size="base"
                    margin={false}
                  >
                    abbiamo piantato
                  </Title>
                  <Title
                    disableAnimation
                    color={"white"}
                    size="base"
                    margin={false}
                  >
                    <strong>60</strong> alberi
                  </Title>
                  <Title
                    disableAnimation
                    color={"white"}
                    size="base"
                    margin={false}
                  >
                    e risparmiato{" "}
                  </Title>
                  <Title disableAnimation color={"white"} size="base">
                    <strong>4590 kg</strong> di CO2!
                  </Title>
                  <div className="w-96">
                    <Text color="white">
                      Tutte le nostre decisioni ed i nostri progetti sono{" "}
                      <strong>data-driven</strong>, ovvero basati sui tuoi dati
                      e misurabili nei loro effetti.
                    </Text>
                  </div>
                  <div className="pt-4 flex flex-col md:flex-row justify-center items-center">
                    <div className="p-2 md:px-4">
                      <a
                        href="https://www.treedom.net/it/organization/trame-digitali/"
                        target="_blank"
                      >
                        <Button bigger>Corri alla nostra foresta!</Button>
                      </a>
                    </div>
                    <div className="hidden md:block p-2 md:px-4">
                      <ReadMore black>Treedom chi?</ReadMore>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="px-12 md:px-0">
            <div
              className="flex flex-row justify-center items-center relative"
              style={{ height: "100vh" }}
              ref={tdvbRef}
            >
              <div
                className=" w-96 absolute flex justify-center items-center bg-primary"
                id="bg-green"
              >
                <div className="absolute" id="treedom">
                  <TdvbSVG className="h-12 md:h-48 w-auto "></TdvbSVG>
                </div>
                <div
                  className="flex flex-col items-center justify-center text-center px-12 md:w-screen"
                  id="text"
                >
                  <div className="md:w-1/2 text-center">
                    <Title color="white">TDVB Group</Title>
                    <div className="">
                      <Text color="white">
                        <br />
                        Facciamo parte di un gruppo nazionale di agenzie di
                        comunicazione riconosciute per esperienza, competenza e
                        capacità. Scegliere Trame Digitali signfica poter
                        accedere alle professionalità di tutto il gruppo.
                      </Text>
                    </div>
                    <div className="pt-4 flex flex-col md:flex-row justify-center items-center">
                      <div className="hidden md:block p-2 md:px-4">
                        <ReadMore black>
                          <a href="https://www.tdvbgroup.com" target="_blank">
                            Il sito del gruppo
                          </a>
                        </ReadMore>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
}

export const homepageQuery = graphql`
  query HOMEPAGE_QUERY {
    allContentfulCustomer(
      filter: { node_locale: { eq: "it" }, mostvalue: { eq: true } }
      sort: { order: DESC, fields: contentful_id }
      limit: 21
    ) {
      nodes {
        name
        logo {
          gatsbyImageData(
            width: 75
            formats: [PNG, WEBP]
            placeholder: TRACED_SVG
          )
        }
      }
    }
    allContentfulBlogPost(
      filter: {
        node_locale: { eq: "it" }
        subtitle: { subtitle: { glob: "*" } }
      }
      limit: 5
    ) {
      nodes {
        createdAt
        slug
        title
        featured
        subtitle {
          subtitle
        }
        image {
          gatsbyImageData(formats: [WEBP, PNG])
        }
        category {
          slug
          name
          color
        }
        author {
          picture {
            gatsbyImageData
          }
          name
          tagline
        }
      }
    }
  }
`;

export default IndexPage;
